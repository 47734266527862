import React from "react";

class TipSelectForm extends React.Component {
    constructor(props) {
        super(props);

        this.fee = 2;

        this.state = {
            amount: null,
            clientAcceptedToS: false,
            clientSupportsTransferFee: false,
            customAmount: null,
            errorMessage: null,
        }
    }

    continueHandler = (event) => {
        event.preventDefault();

        const { amount, clientSupportsTransferFee } = this.state;
        const totalAmount = clientSupportsTransferFee ? amount + this.fee : amount;

        if (totalAmount) {
            this.props.continueHandler(amount, totalAmount);
        }
    }

    selectAmount = (value) => this.setState({ amount: value, customAmount: null, errorMessage: null });
    checkboxHandler = (event) => {
        console.log(event.target.name, event.target.checked);
        this.setState({ [event.target.name]: event.target.checked });
    }


    handleCustomAmountChange = (event) => {
        const numberValue = parseFloat(event.target.value);
        if (Number.isInteger(numberValue)) {
            if (numberValue > 1000 || numberValue < 1) {
                this.setState({
                    amount: null,
                    customAmount: numberValue,
                    errorMessage: "Please select an amount between 1 and 1000 AED",
                });

            } else {
                this.setState({ amount: numberValue, customAmount: numberValue, errorMessage: null });
            }
        } else {
            const err = (!event.target.isInteger && event.target.value.length > 0) ? "The custom amount must be an integer value" : null;
            this.setState({ amount: null, customAmount: null, errorMessage: err })
        }
    }

    render() {
        const name = this.props.name;
        const {
            amount,
            clientSupportsTransferFee,
            clientAcceptedToS,
            customAmount,
            errorMessage,
        } = this.state;

        const totalAmount = clientSupportsTransferFee ? amount + this.fee : amount;

        const values = [5, 10, 20];
        const valueButtons = values.map(value => (
            <div className="col-4 text-center" key={`div-${value}`}>
                <button
                    key={`${value}`}
                    type="button"
                    className={this.state.amount === value ? "btn btn-amount pb-0 active" : "btn btn-amount pb-0"}
                    onClick={this.selectAmount.bind(this, value)}
                >
                    <span style={{ display: 'block' }}>{value}</span>
                    <span style={{ display: 'block', fontSize: 12, marginTop: -8, opacity: 0.6 }}>AED</span>
                </button>
            </div>
        ));

        const continueable = !!amount && clientAcceptedToS;

        return (
            <form onSubmit={this.continueHandler}>
                <p className="mt-3">Select tip amount</p>
                <div className="row mb-3">
                    {valueButtons}
                </div>
                <div className="form-group">
                    <label htmlFor="amount" className="mb-0"><small>Or chose a custom amount to tip:</small></label>
                    <div className="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">AED</span>
                        </div>
                        <input
                            type="number"
                            className="form-control"
                            name="amount"
                            value={customAmount || ''}
                            onChange={this.handleCustomAmountChange}
                            onKeyDown={(ev) => (ev.key === 'e' || ev.key === 'E') && ev.preventDefault()}
                        />
                    </div>
                </div>
                <div className="custom-control custom-checkbox mb-3">
                    <input className="custom-control-input" name="clientSupportsTransferFee" type="checkbox" checked={clientSupportsTransferFee} onChange={this.checkboxHandler} id="support-transfer-fee" />
                    <label className="custom-control-label" htmlFor="support-transfer-fee">Include processing fee so that {name} can receive their full tip amount.</label>
                </div>
                <div className="custom-control custom-checkbox mb-3">
                    <input className="custom-control-input" name="clientAcceptedToS" type="checkbox" checked={clientAcceptedToS} onChange={this.checkboxHandler} id="accept-tos" />
                    <label className="custom-control-label" htmlFor="accept-tos">Agree to TippTap's terms of service and privacy policy</label>
                </div>
                {(!!amount)
                    ? <p className="text-center pt-2 pb-1" style={{ background: '#00f5c8' }}>Total amount <span className="text-yellow"> {totalAmount} AED</span></p>
                    : ((!errorMessage) && <p className="pt-2 pb-1">&nbsp;</p>)}
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                <button type="submit" className="btn btn-primary btn-block btn-lg" disabled={!continueable}>Continue</button>
                <div className="row mt-4 d-flex justify-content-center">
                    <a className="btn btn-sm btn-link text-muted" href="https://tipptap.com/impressum" target="_blank">Impressum</a> 
                    <a className="btn btn-sm btn-link text-muted" href="https://tipptap.com/privacy-policy" target="_blank">Privacy Policy</a>
                </div>
            </form>
        )
    }
}

export default TipSelectForm;