import React from 'react';

class RedirectPage extends React.Component {
    componentDidMount() {
        window.location.replace('https://www.tipptap.com');
    }

    render() {
        return(<></>);
    }
}

export default RedirectPage;
