import logo from './logo.svg';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import TransactionPage from './components/TransactionPage';
import UserPage from './components/UserPage';
import RedirectPage from './components/RedirectPage';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);


const App = () => {
  return (
    <div className="App container">
      <div className="content">
      <Elements stripe={stripePromise}>
        <div className="col col-md-4 offset-md-4 py-3">
          <img src={logo} alt="TippTap logo" />
          <Router>
            <Switch>
              <Route path="/trx/:trxID" component={TransactionPage}></Route>
              <Route path="/:userID" component={UserPage}></Route>
              <Route path="/" component={RedirectPage}></Route>
            </Switch>
          </Router>
        </div>
        </Elements>
      </div>
    </div>
  );
}

export default App;
