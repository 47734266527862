import { Page, Text, View, Document, StyleSheet, Svg } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    }
});

// Create Document Component
export const Receipt = (props) => {
    const {
        amount,
        currency,
        receiptId,
        timestamp,
    } = props;
    const receiptDate = formatTimestamp(timestamp);

    return <Document>
        <Page size="A5" orientation="landscape" style={styles.page}>
            <View style={styles.section}>
                <Text style={{ fontWeight: 'bold' }}>TippTap Receipt {receiptId}</Text>
                <Text>--------------------</Text>
                <Text style={{ textTransform: 'uppercase' }}>Total amount: {amount} {currency}</Text>
                <Text>Date: {receiptDate}</Text>
                <Text></Text>
                <Text>TippTap company address and business info.</Text>
            </View>
        </Page>
    </Document>
};


function formatTimestamp(timestamp) {
    const dtFormat = new Intl.DateTimeFormat('en-GB', {
        dateStyle: 'short',
        timeStyle: 'medium',
        // timeZone: 'UTC'
    });

    return dtFormat.format(new Date(timestamp * 1000));
}