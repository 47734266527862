import app from "firebase/app";
import * as functions from 'firebase/functions';
import "firebase/database";

import FirebaseContext, { withFirebase } from './context';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID
};

export default class Firebase {
  constructor() {
    app.initializeApp(config);
    
    if (process.env.NODE_ENV === "development") {
      console.log("💻 RUNNING IN DEVELOPMENT");
      app.functions().useEmulator('localhost', 5001);
    }

    this.db = app.database();
  }

  userRef = (userID) => this.db.ref(`users/${userID}`);
}

export { FirebaseContext, withFirebase };