import React from 'react';
import { Receipt } from './Receipt';
import { PDFDownloadLink } from '@react-pdf/renderer';
import heart from '../corazon.svg';


class TransactionPage extends React.Component {
    constructor(props) {
        super(props);

        this.amount = new URLSearchParams(props.location.search).get('amount');
        this.currency = 'aed'; // new URLSearchParams(props.location.search).get('currency');
        this.timestamp = new URLSearchParams(props.location.search).get('timestamp');
        this.transactionId = props.match.params.trxID;
    }

    render() {
        const receiptFilename = "tipptap-receipt-" + Date.now() + ".pdf";
        return (
            <div className="mt-3 text-center">
                <p className="text-left">Thank you for tipping!</p>
                <div className="my-4">
                    <img src={heart} alt="much appreciated" style={{ maxWidth: '160px' }} />
                </div>
                <div className="row">
                    <div className="col-10 offset-1 mt-3">
                        <PDFDownloadLink document={
                            <Receipt
                                amount={this.amount}
                                currency={this.currency}
                                receiptId={this.transactionId}
                                timestamp={this.timestamp}
                            />} fileName={receiptFilename} className="btn btn-primary btn-block">
                            {({ blob, url, loading, error }) => loading ? 'generating...' : 'Download receipt'}
                        </PDFDownloadLink>
                    </div>
                    <div className="col-10 offset-1 mt-3">
                        <a href="https://tipptap.com" className="btn btn-block btn-outline-primary" alt="learn more about TippTap">Learn more about TippTap</a>
                    </div>
                </div>
                <div className="row mt-4 d-flex justify-content-center">
                    <a className="btn btn-sm btn-link text-muted" href="https://tipptap.com/impressum" target="_blank">Impressum</a>
                    <a className="btn btn-sm btn-link text-muted" href="https://tipptap.com/privacy-policy" target="_blank">Privacy Policy</a>
                </div>
            </div>

        );
    }
}


export default TransactionPage;