import React from 'react';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import ActivityIndicator from './helpers/activityIndicator'
import PayWithCardForm from './PayWithCardForm'

// we want to display the PaymentRequest button primarily
// and the pay with card option as a fallback
// if Payment Request Button is not viable we want the
// payemnt with card to be the main option


class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);

    this.stripe = props.stripe
    this.elements = props.elements

    if (!this.stripe || !this.elements) {
      console.error("SOMETHING WENT TERRIBLY WRONG");
    }

    this.state = {
      activity: true,
      canMakePayment: false,
      clientSecret: null,
      errorMessage: null,
      paymentRequest: null,
      serverError: false,
      timestamp: null,
      transactionId: null,
    }
  }

  onPaymentSuccess = () => {
    if (!!this.state.transactionId) {
      window.location.assign(`/trx/${this.state.transactionId}?amount=${this.props.totalAmount}&timestamp=${this.state.timestamp}`);
    }
  }

  componentDidMount() {
    (async () => {
      const trxData = {
        'amount': this.props.totalAmount * 100,
        'client_supports_fee': (this.props.tipAmount !== this.props.totalAmount),
        'connected_account': this.props.connectedAccount,
        'first_name': this.props.firstName,
        'last_name': this.props.lastName,
        'receiver_id': this.props.receiverId,
      };
      const clientSecretEndpoint = process.env.REACT_APP_CLIENT_SECRET_ENDPOINT

      try {
        const serverResponse = await fetch(clientSecretEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(trxData),
        });

        const jsonResponse = await serverResponse.json();
        const { client_secret: clientSecret, timestamp: timestamp, transaction_id: transactionId } = jsonResponse;
        this.setState({ clientSecret, timestamp, transactionId });
        this.showPaymentForm();
      } catch (err) {
        console.error(`Server connection failed with error: ${err.message}`);
        this.setState({
          activity: false,
          serverError: true,
          errorMessage: "We are experiencing technical difficulties at the moment.",
        })
      }
    })();
  }

  showPaymentForm = () => {
    const amountInHundreds = this.props.totalAmount * 100;
    const beneficiaryName = this.props.firstName;

    if (!amountInHundreds || !beneficiaryName) {
      console.error("");
      return
    }

    if (this.stripe) {
      const paymentRequest = this.stripe.paymentRequest({
        country: 'AE',
        currency: 'aed',
        total: {
          amount: amountInHundreds,
          label: `Tip for ${beneficiaryName}`,
        },
        requestPayerEmail: true,
      });

      paymentRequest.canMakePayment().then(result => {
        if (result) {
          this.attachPaymentListener(paymentRequest);
          this.setState({ paymentRequest: paymentRequest, canMakePayment: true, activity: false });
        } else {
          this.setState({ payWithCard: true, canMakePayment: false, activity: false });
        }
      });
    }
  }

  attachPaymentListener = (paymentRequest) => {
    paymentRequest.on('paymentmethod', async (ev) => {
      this.setState({ activity: true, errorMessage: null });

      const { paymentIntent, error: confirmError } = await this.stripe.confirmCardPayment(
        this.state.clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: true },
      );

      this.setState({ activity: false });
      if (confirmError) {
        this.setState({ errorMessage: confirmError.error.message });
        ev.complete('fail');
      } else {
        ev.complete('success');
        if (paymentIntent && paymentIntent.status === "requires_action") {
          const { error } = await this.stripe.confirmCardPayment(this.clientSecret);
          if (error) {
            this.setState({ canMakePayment: false, errorMessage: error.message });
          } else {
            this.onPaymentSuccess();
          }
        } else {
          this.onPaymentSuccess();
        }
      }
    });
  }


  handleCardPayment = async (cardElement) => {
    const { paymentIntent, error: confirmError } = await this.stripe.confirmCardPayment(
      this.state.clientSecret,
      {
        payment_method: {
          card: cardElement,
        }
      },
      { handleActions: false },
    );

    if (confirmError) {
      this.setState({ errorMessage: confirmError.error.message });
    } else {
      if (paymentIntent && paymentIntent.status === "requires_action") {
        const { error } = await this.stripe.confirmCardPayment(this.clientSecret);
        if (error) {
          this.setState({ canMakePayment: false, errorMessage: error.message });
        } else {
          this.onPaymentSuccess();
        }
      } else {
        this.onPaymentSuccess();
      }
    }
  }


  render() {
    const {
      activity,
      canMakePayment,
      errorMessage,
      paymentRequest,
      serverError,
    } = this.state;

    const {
      firstName,
      tipAmount,
      totalAmount,
    } = this.props;

    const clientSupportsFee = tipAmount !== totalAmount;

    if (activity) {
      return (<>
        <p className="text-center mt-5">Total amount <span className="text-yellow"> {totalAmount} AED</span></p>
        <div className="py-3 text-center"><ActivityIndicator /></div>
      </>)
    }

    if (serverError) { return <ErrorMessageDisplay message={errorMessage} /> }

    if (canMakePayment) {
      return (
        <div>
          <p className="text-center mt-5">Total amount <span className="text-yellow"> {totalAmount} AED</span></p>
          {errorMessage && <ErrorMessageDisplay message={errorMessage} />}
          <PaymentRequestButtonElement options={{ paymentRequest }} />
          <div className="my-3">
            <small>Or enter your payment details below.</small>
            <PayWithCardForm
              elements={this.elements}
              stripe={this.stripe}
              isMainMethod={!canMakePayment}
              clientSecret={this.state.clientSecret}
              onPaymentSuccess={this.onPaymentSuccess}
            />
          </div>
          <hr />
          <button className="btn btn-block btn-outline-primary" onClick={this.props.changeTipAmount}>Change tip amount</button>
          <div className="row mt-4 d-flex justify-content-center">
            <a className="btn btn-sm btn-link text-muted" href="https://tipptap.com/impressum" target="_blank">Impressum</a>
            <a className="btn btn-sm btn-link text-muted" href="https://tipptap.com/privacy-policy" target="_blank">Privacy Policy</a>
          </div>
        </div>
      )
    }

    return (<div>
      <p className="text-center mt-5">Total amount <span className="text-yellow"> {totalAmount} AED</span></p>
      {errorMessage && <ErrorMessageDisplay message={errorMessage} />}
      <div className="mt-3">
        <small>Enter your payment details below.</small>
        <PayWithCardForm
          elements={this.elements}
          stripe={this.stripe}
          isMainMethod={!canMakePayment}
          clientSecret={this.state.clientSecret}
          onPaymentSuccess={this.onPaymentSuccess}
        />
      </div>
      <hr />
      <button className="btn btn-block btn-outline-primary" onClick={this.props.changeTipAmount}>Change tip amount</button>
      <div className="row mt-4 d-flex justify-content-center">
        <a className="btn btn-sm btn-link text-muted" href="https://tipptap.com/impressum" target="_blank">Impressum</a>
        <a className="btn btn-sm btn-link text-muted" href="https://tipptap.com/privacy-policy" target="_blank">Privacy Policy</a>
      </div>
    </div>)
  }
}


const ErrorMessageDisplay = (props) => (
  <div className="alert alert-danger mt-3">{props.message}<br /><small>Please contact support if this issue persists.</small></div>
)


const CheckoutFormWithElements = (props) => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <CheckoutForm {...props} elements={elements} stripe={stripe} />
      )}
    </ElementsConsumer>
  );
};

export default CheckoutFormWithElements;