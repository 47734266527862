import React from 'react';
import { withFirebase } from '../services/firebase';
import ActivityIndicator from './helpers/activityIndicator';
import TipSelectForm from './TipSelectForm';
import CheckoutFormWithElements from './CheckoutForm';


class UserPage extends React.Component {
    constructor(props) {
        super(props);

        this.userID = props.match.params.userID;

        this.state = {
            checkout: false,
            connectedAccount: null,
            firstName: null,
            loading: true,
            lastName: null,
            messageToClient: null,
            tipAmount: null,
            totalAmount: null,
        }
        this.userRef = props.firebase.userRef(this.userID);
    }

    componentDidMount() {
        const userKeys = ['first_name', 'last_name', 'status', 'connected_account'];
        const userDataPromises = userKeys.map(key => this.userRef.child(key).once('value'));

        Promise.all(userDataPromises).then(snapshots => {
            let userData = {} // first_name, status
            snapshots.forEach(snap => userData[snap.key] = snap.val());
            if (!!userData.status && !!userData.first_name && !!userData.last_name && !!userData.connected_account) {
                if (userData.status === 'active') { 
                    this.setState({ 
                        loading: false, 
                        firstName: userData.first_name, 
                        lastName: userData.last_name, 
                        connectedAccount: userData.connected_account,
                    }); 
                }
                else { this.setState({ loading: false, messageToClient: `${userData.first_name} can not receive tips currently.` }); }
            } else {
                this.setState({ loading: false, messageToClient: 'This page does not have a valid URL' });
            }
        }).catch((error) => console.log(error.message) );
    }

    confirmAmount = (tipAmount, totalAmount) => this.setState({ checkout: true, tipAmount: tipAmount, totalAmount: totalAmount });
    changeTipAmount = () => this.setState({ checkout: false });

    render() {
        const {
            checkout,
            connectedAccount,
            firstName,
            loading,
            lastName,
            messageToClient,
            tipAmount,
            totalAmount,
        } = this.state;
        const receiverId = this.userID;

        if (loading) { <ActivityIndicator /> }
        if (!!messageToClient) { return <ClientNotification message={messageToClient} /> }

        if (checkout && !!totalAmount) {
            return <CheckoutFormWithElements
                connectedAccount={connectedAccount}
                firstName={firstName}
                lastName={lastName}
                receiverId={receiverId}
                changeTipAmount={this.changeTipAmount}
                tipAmount={tipAmount}
                totalAmount={totalAmount}
            />
        }

        return <TipSelectForm name={firstName} continueHandler={this.confirmAmount} />
    }
}


const ClientNotification = (props) => {
    return (
        <div className="alert alert-info mt-3">
            <p>{props.message}</p>
            <p>If you think this is an error please contact <a href="https://www.tipptap.com/contact">support</a>.</p>
        </div>
    )
}


export default withFirebase(UserPage);