import { useState } from 'react';
import { CardElement } from '@stripe/react-stripe-js';


const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      backgroundColor: '#ffffff',
      padding: '12px',
      lineHeight: '36px',
      fontSize: '18px',
      color: '#1e3c5a',
      '::placeholder': {
        color: '#cccccc',
      },
    },
    invalid: {
      color: '#ffaabe',
    },
  },
  classes: {
    base: 'mb-3',
  },
};


const PayWithCardForm = (props) => {
  const { stripe, elements } = props;
  const [error_message, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    handleCardPayment(cardElement);
  }


  const handleCardPayment = async (cardElement) => {
    const { paymentIntent, error: paymentError } = await stripe.confirmCardPayment(
      props.clientSecret,
      { payment_method: { card: cardElement, } },
      { handleActions: false },
    );

    if (paymentError) {
      setErrorMessage(paymentError.message);
    } else {
      if (paymentIntent && paymentIntent.status === "requires_action") {
        const { error } = await stripe.confirmCardPayment(props.clientSecret);
        if (error) {
          setErrorMessage(error.message);
        } else {
          props.onPaymentSuccess();
        }
      } else {
        props.onPaymentSuccess();
      }
    }
  }


  return (
    <form onSubmit={handleSubmit}>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
      {error_message && <ErrorMessageDisplay message={error_message} />}
      <button type="submit"
        className={props.isMainMethod ? "btn btn-xs btn-primary btn-block mt-2" : "btn btn-xs btn-secondary btn-block mt-2"}
        disabled={!stripe}>
        Pay with card
        </button>
    </form>
  );
}

const ErrorMessageDisplay = (props) => (
  <div className="alert alert-danger mt-3"><small>{props.message}</small></div>
)

export default PayWithCardForm;